import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PatientServiceService } from '../services/patient-service.service';

@Component({
  selector: 'app-patient-login',
  templateUrl: './patient-login.component.html',
  styleUrls: ['./patient-login.component.css']
})
export class PatientLoginComponent implements OnInit {
  patientForm!: FormGroup;
  patients: any;
  isValid: boolean = false;
  errorMessage: string = '';
  isLoader: boolean = false;

  constructor(private router:Router, private patientService: PatientServiceService) { }

  ngOnInit(): void {
    this.getPatients();
    this.patientForm = new FormGroup({
      patientName: new FormControl('', Validators.required)  // Initialize form control with validators
    });
  }
  onSubmit(): void {
    if (this.patientForm.valid) {
      this.isLoader = true;
      const patientId = this.patients.patientList.filter((x: any) => x.patientName === this.patientForm.value.patientName)[0].id;
      this.patientService.getPatientDetails(patientId, 'api/patient/').subscribe({
        next: (response) => {
          if(response !=undefined && response !=null) {
            sessionStorage.setItem('Selected Patient', JSON.stringify(response));
            this.router.navigate(['/observation'])
            this.isLoader = false;
          } else {
            alert('Oops! Something went wrong!')
          }
        },
        error: (error) => {
          console.error('Error details:', error);
        },
        complete: () => {
          console.log('Data fetching complete.');
        }
      });
    } else {
      console.log('Form is invalid!');
    }
  }


  getPatients() {
    this.patientService.getAllPatients().subscribe(res => {
      
      this.patients = res;
    })
  }

  checkValidity(event: any) {
    this.errorMessage = '';
    
    this.errorMessage = event.target.value === '' ? 'Patient Name cannot be empty!' : '';
    const patient = this.patients.patientList.filter((x: any) => x.patientName === event.target.value);
    
    if(patient.length > 0 && this.errorMessage === '') {
      this.errorMessage = '';
      this.isValid = true;
    } else if(patient.length == 0 && this.errorMessage == '') {
      this.errorMessage = 'This patient does not exist!';
      this.isValid = false;
    } else {
      this.errorMessage = this.errorMessage
      this.isValid = false;
    }
  }


}
