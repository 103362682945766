import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
 
  private config: any;
 
  constructor() {
    this.config = window['envConfig']; // Access the injected config
    console.log('config', this.config);
  }
 
  get apiUrl(): string {
    console.log('domain name', this.config.DOMAIN_NAME);
    return this.config.DOMAIN_NAME;
  }

  get jsonUrl(): string {
    console.log('json url', this.config.jsonBaseUrl);
    return this.config.jsonBaseUrl;
  }
}

