import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { environment } from '../../environments/environment';
import { ConfigService } from './config-service.service';

@Injectable({
  providedIn: 'root'
})
export class PatientServiceService {
  // apibaseUrl: any = environment.DOMAIN_NAME;
  apibaseUrl: any;
  jsonUrl!: string;

  constructor(private http: HttpClient, private configService: ConfigService) {}
 
   getAllPatients() {
    this.jsonUrl = this.configService.jsonUrl;
      console.log('url', this.apibaseUrl);
    return this.http.get<any>(this.jsonUrl+'patients.json');
   }
   

   savePatientsData(payload: any, endpoint: any) {
    // /api/vitals
    console.log('endpoint url', endpoint)
    const url = `${this.apibaseUrl}/${endpoint}`
    return this.http.post(url, payload)
  }

   getPatientDetails(id: number, endpoint: any) {
      this.apibaseUrl = this.configService.apiUrl;
      console.log('url', this.apibaseUrl);
      const url = `${this.apibaseUrl}/${endpoint}`
      return this.http.get(url +id)
   }

  }