import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientLoginComponent } from './patient-login/patient-login.component';
import { HealthdataComponent } from './healthdata/healthdata.component';

const routes: Routes = [
  {
    path: '', component: PatientLoginComponent
  },
  {
    path: 'observation', component: HealthdataComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
