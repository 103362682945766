import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { PatientServiceService } from '../services/patient-service.service';
import { DatePipe } from '@angular/common';
import { BarElement, CategoryScale, ChartConfiguration, ChartData, ChartOptions, ChartType, Legend, LinearScale, registerables, Title, Tooltip } from 'chart.js';
import { Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


@Component({
  selector: 'app-healthdata',
  templateUrl: './healthdata.component.html',
  styleUrls: ['./healthdata.component.css']
})
export class HealthdataComponent implements OnInit {
  @ViewChild(MatAccordion)
  accordion!: MatAccordion;
  selectedPatient!: any | null;
  patientData : any;
  patients: any;
  today: Date = new Date();
  vitals: any;
  painRange: any[] = ['0','1','2','3','4','5','6','7','8','9','10'];
  latestRecording: any;
  age!: number;
  bmi!: number;
  height: any;
  weight: any;
  bodyMassIndex!: any;
  circumference: any;
  Pulse: any;
  pulseOximetry: any;
  respiration: any;
  temperature: any;
  pain: any;
  bloodPressure!: string;
  uplimit: any;
  belowlimit: any;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective; 
  activePanel: number | undefined;

  // Below 3 variables are temp and will be removed
  isLinear = false;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;

  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Pulse (BPM)' }, beginAtZero: true }
    },
  };

  public heightBarChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Height (inches)' }, beginAtZero: true }
    },
  };

  public weightBarChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Weight (lbs)' }, beginAtZero: true }
    },
  };

  public BMIBarChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'BMI' }, beginAtZero: true }
    },
  };

  public painBarChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Pain' }, beginAtZero: true }
    },
  };

  public pulseOBarChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Pulse Oximetry' }, beginAtZero: true }
    },
  };

  public respBarChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Respiration' }, beginAtZero: true }
    },
  };

  public girthBarChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Circumference' }, beginAtZero: true }
    },
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Temperature' }, beginAtZero: false, min: 95, max:102 }
    },
    spanGaps: true
  };

  public bpLineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        title: { display: true, text: 'Date' }
      },
      y: {
        ticks: {
          stepSize: 40
        },
        min: 60,
        beginAtZero: true,
        title: { display: true, text: 'Temperature' },
      },
    },
    spanGaps: true
  };

  public barChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Pulse', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };

  public heightBarChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Height', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };

  public weightBarChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Weight', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };

  public BMIBarChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Body Mass Index', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };

  public painBarChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Pain', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };

  public pulseOBarChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Pulse Oximetry', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };

  public respBarChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Respiration', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };

  public girthBarChartData: ChartData<'bar'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Circumference', backgroundColor: '#0071bc',  barThickness: 20 }
    ]
  };
  public lineChartData: ChartData<'line'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      { data: [], label: 'Temperature', borderColor: '#0071bc', tension: 0.4,
        backgroundColor: '#0071bc',
        pointBackgroundColor: '#0071bc',
        pointRadius: 6,
        pointHoverRadius: 8,
        borderWidth: 2, 
      }, 
      {
        label: 'High',
        data: Array(12).fill(100), // Static value for all x-axis points
        borderColor: 'red',
        borderWidth: 2,
        borderDash: [10, 5], // Dashed line
        pointRadius: 0, // No points
        fill: false,
      },
      // Dashed "Low" threshold line
      {
        label: 'Low',
        data: Array(12).fill(97), // Static value for all x-axis points
        borderColor: 'cyan',
        borderWidth: 2,
        borderDash: [10, 5], // Dashed line
        pointRadius: 0, // No points
        fill: false,
      }
    ]
  };

  public bpChartData: ChartData<'line'> = {
    labels: ['02/24', '03/24', '04/24', '05/24', '06/24', '07/24', '08/24', '09/24', '10/24', '11/24', '12/24', '01/25'],
    datasets: [
      {
        label: 'Systolic BP',
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
        tension:0.4,
        pointBackgroundColor: '#0071bc',
        pointRadius: 6,
        pointHoverRadius: 8,
      },
      {
        label: 'Diastolic BP',
        data: [],
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        fill: false,
        tension:0.4,
        pointBackgroundColor: '#0071bc',
        pointRadius: 6,
        pointHoverRadius: 8,
      },
    ],
  };

  graphVitals: any;
  xAxisValues: any;
  pyAxisValues: any;
  tyAxisValues: any;
  hyAxisValues: any;
  wyAxisValues: any;
  payAxisValues: any;
  poyAxisValues: any;
  ryAxisValues: any;
  bpyAxisValues: any;
  bmiyAxisValues: any;
  girthyAxisValues: any;

  showPulseChart!: boolean;
  showBodyTemperature!: boolean;
  showHeightChart!: boolean;
  showWeightChart!: boolean;
  showBMIChart!: boolean;
  showPChart!: boolean;
  showPoChart!: boolean;
  showRChart!: boolean;
  showBPChart!: boolean;

  isLoader!: boolean;
  patientCopy!: any; 

  isBPPanelOpen = false;
  isCPanelOpen = false;
  isHPanelOpen = false;
  isWPanelOpen = false;
  isBMIPanelOpen = false;
  isPPanelOpen = false;
  isPuPanelOpen = false;
  isPuOPanelOpen = false;
  isRPPanelOpen = false;
  isTPanelOpen = false;
  bmiStatus!: string;
  categoryClass!: string;
  bpStatus!: string;
  bpCategoryClass!: string;
  painStatus!: string;
  weightStatus!: string;
  heightStatus!: string;
  pulseStatus!: string;
  pulseOStatus!: string;
  respStatus!: string;
  tempStatus!: string;
  circumferenceStatus!: string;

  showGirthChart!: boolean;
  
  isFormEmpty: boolean = true;
   puvalues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   tvalues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   hvalues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   wvalues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   pvalues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   povalues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   rvalues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   bmiValues: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
   girthValues: string[] = ['', '', '', '', '', '', '', '', '', '', ''];
   SystolicBP: string[] = ['', '', '', '', '', '', '', '', '', '', ''];
   diastolicBP: string[] = ['', '', '', '', '', '', '', '', '', '', ''];
   systolicData: any;
   dystolicData: any;
   isBPErrorMessage!: string;
   vitalsForm!:FormGroup;
constructor(private patientService: PatientServiceService, private datePipe: DatePipe, private fb: FormBuilder) { }

  ngOnInit(): void {
    const patient =  sessionStorage.getItem('Selected Patient');
    this.selectedPatient = patient !=null ? JSON.parse(patient) : '';
    this.patientCopy = structuredClone(this.selectedPatient);
    this.newFormValidations();
    this.vitalsForm?.valueChanges.subscribe(value => {
      this.validationChange(value);
    });
    this.getPatientData(false);
  }  


  getPatientData(isUpdated: boolean) {
    if(this.selectedPatient !=undefined && this.selectedPatient.vitals && this.selectedPatient.vitals.length > 0) {   
      this.getFormattedContact();
      this.latestRecording = this.selectedPatient.vitals.reduce((prev: any, current: any) => ((prev.recordedAt > current.recordedAt) ?
      prev : current), 0) //returns object
      this.showRightSideContent();
      this.xAxisValues = this.barChartData.labels;
      if(isUpdated == false) {
        this.patientCopy.vitals.forEach((ele: any) => {
          var date = new Date(ele.recordedAt);
          var mm = (date.getMonth() + 1).toString().padStart(2, '0');
          var yy = date.getFullYear().toString().slice(-2).padStart(2, '0');
          var newDate = mm + '/'+ yy;
          ele.recordedAt = newDate;
          
        });
        this.renderGraph();
       } else {
          const today = this.getTodayDate();
          const todayRecordIndex = this.xAxisValues.findIndex((x: any) => x === today);
          const existingDataRecordIndex =  this.patientCopy.vitals.findIndex((x: any) => x.recordedAt === today);
          if(existingDataRecordIndex > -1 && todayRecordIndex > -1) {
            this.puvalues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].pulse;
            this.tvalues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].temperature;
            this.hvalues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].heightIn;
            this.wvalues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].weightLbs;
            this.pvalues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].pain;
            this.povalues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].pulseOximetry;
            this.rvalues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].respiration;
            this.bmiValues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].bmi;
            this.girthValues[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].circumference;
            this.SystolicBP[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].bp.split('/')[0]
            this.diastolicBP[todayRecordIndex] = this.patientCopy.vitals[existingDataRecordIndex].bp.split('/')[1]
        } 
        this.pyAxisValues = this.puvalues;
        this.tyAxisValues = this.tvalues;
        this.hyAxisValues = this.hvalues;
        this.wyAxisValues = this.wvalues;
        this.payAxisValues = this.pvalues;
        this.poyAxisValues = this.povalues;
        this.ryAxisValues = this.rvalues;
        this.bmiyAxisValues = this.bmiValues;
        this.girthyAxisValues = this.girthValues;
        this.systolicData = this.SystolicBP;
        this.dystolicData = this.diastolicBP;
        this.isLoader = false;
       }
      }
      this.getAge();
      if(isUpdated === false) {
        this.getBMI();
      }
  }


  getAge() {
    const bdayDate = new Date(this.selectedPatient.dob).getFullYear();
    const today = new Date().getFullYear();
    this.age = today - bdayDate;
  }

  getBMI(height?: any, weight?: any) {
    if(this.height !=undefined && this.height != '' && this.height != null && this.weight != undefined && this.weight != '' && this.weight != null) {
      const newHeight = height * height
      const bmi =(weight/newHeight) * 703;
      this.bodyMassIndex = parseFloat(bmi.toFixed(2));
      this.getBMIStatus();
    } else {
      const newHeight = this.latestRecording.heightIn * this.latestRecording.heightIn
      const bmi =(this.latestRecording.weightLbs/newHeight) * 703;
      this.latestRecording.bmi = parseFloat(bmi.toFixed(2));
    }
    
  }

  updateData() {
    //  Actual Payload
    const payload = {
        "temperature": parseInt(this.temperature),
        "pulse": parseInt(this.Pulse),
        "respiration": parseInt(this.respiration),
        "bp": this.bloodPressure,
        "weightLbs": parseInt(this.weight),
        "bmi": this.bodyMassIndex,
        "heightIn": parseInt(this.height),
        "pain": this.pain === undefined ? 0 : parseInt(this.pain),
        "enteredBy": "DR H",
        "pulseOximetry": parseInt(this.pulseOximetry),
        "circumference": this.circumference,
        "recordedAt": new Date(),
        "hospitalId":2,
        "patientId": this.selectedPatient.id
    }
    this.isLoader = true;
    this.patientService.savePatientsData(payload, 'api/vitals').subscribe({
        next: (response) => {
         this.patientService.getPatientDetails(this.selectedPatient.id, 'api/patient/').subscribe(data => {
          if(data !=undefined && data !=null) {
            sessionStorage.setItem('Selected Patient', JSON.stringify(data));
            this.selectedPatient = data;
            this.patientCopy = structuredClone(this.selectedPatient);
            this.patientCopy.vitals.forEach((ele: any) => {
              var date = new Date(ele.recordedAt);
              var mm = (date.getMonth() + 1).toString().padStart(2, '0');
              var yy = date.getFullYear().toString().slice(-2).padStart(2, '0');
              var newDate = mm + '/'+ yy;
              ele.recordedAt = newDate;
            });
            this.closeAllPanels();
            this.clearData();
            this.getPatientData(true);
            }
          });  
        },
        error: (error) => {
          console.error('Error details:', error);
        },
        complete: () => {
          console.log('Data fetching complete.');
        }
      });
  }

  getValue(event: any, vitalType: string, limit?: any) {
    if(vitalType === 'heightIn' || vitalType === 'weightLbs') {
      if(vitalType === 'heightIn' && event.target.value != '') {
        this.height = event.target.value;
      } else if(vitalType === 'heightIn' && event.target.value === '') {
        this.isFormEmpty = true;
        this.height = event.target.value;
        this.heightStatus = '';
      }
  
      if(vitalType === 'weightLbs' && event.target.value != '') {
        this.weight = event.target.value;
      } else if (vitalType === 'weightLbs' && event.target.value === '') {
        this.weight = event.target.value;
        this.isFormEmpty = true;
        this.weightStatus = '';
      }
  
      if(this.height !=undefined && this.height != '' && this.height != null && this.weight != undefined && this.weight != '' && this.weight != null) {
        this.getBMI(this.height, this.weight)
      } else {
        this.bodyMassIndex = '';
        this.bmiStatus = '';
        this.categoryClass = '';
      }
    } else if (vitalType === 'bp') {
       if(event.target.value != '') {
        if(limit === 'upperlimit') {
          this.uplimit = event.target.value
        } else {
         this.belowlimit = event.target.value;
        }
        if(this.uplimit !=undefined && this.belowlimit !=undefined) {
         this.bloodPressure = this.uplimit + '/' + this.belowlimit;
         this.getBPStatus(this.uplimit, this.belowlimit);
        }
       } else {
         this.isFormEmpty = true;
         if(limit === 'upperlimit') {
          this.uplimit = event.target.value
        } else {
         this.belowlimit = event.target.value;
        }
         this.bpStatus = '';
       }
    } else if(vitalType === 'circumference') {
      if(event.target.value != '') {
        this.circumference = event.target.value;
        if((this.circumference >=28 && this.circumference <=40)) {
          this.circumferenceStatus = 'Normal';
        } else if(this.circumference > 40) {
         this.circumferenceStatus = 'Abnormal'
        } else {
          this.circumferenceStatus = 'Abnormal'
        }
      } else {
        this.isFormEmpty = true;
        this.circumference = event.target.value;
        this.circumferenceStatus = '';
      }
    } else if (vitalType === 'pain'){
      if(event.target.value != '') {
        this.pain = event.target.value;
      } else {
        this.isFormEmpty = true;
        this.pain = event.target.value;
        this.painStatus = '';
      }
    } else if(vitalType === 'pulse') {
      if(event.target.value != '') {
        this.Pulse = event.target.value;
      } else {
        this.isFormEmpty = true;
        this.Pulse = event.target.value;
        this.pulseStatus= '';
      }
      if(this.Pulse != '' && this.Pulse != undefined && this.Pulse >= 60 && this.Pulse <= 100) {
        this.pulseStatus = 'Normal';
      } else if(this.Pulse != '' && this.Pulse != undefined && this.Pulse <60){
        this.pulseStatus = 'low';
      } else if(this.Pulse !='' && this.Pulse !=undefined && this.Pulse >100) {
        this.pulseStatus = 'High'
      }
    } else if(vitalType === 'pulseOximetry') {
       if(event.target.value != '') {
        this.pulseOximetry = event.target.value;
      } else {
        this.isFormEmpty = true;
        this.pulseOximetry = event.target.value;
        this.pulseOStatus = ''
      }
      if(this.pulseOximetry != '' && this.pulseOximetry != undefined && this.pulseOximetry >= 90 && this.pulseOximetry <= 100) {
        this.pulseOStatus = 'Normal';
      } else if (this.pulseOximetry != '' && this.pulseOximetry != undefined && this.pulseOximetry <90) {
        this.pulseOStatus = 'low';
      } else {
        console.log('Not possibly correct');
      }
    } else if (vitalType === 'respiration') {
      if(event.target.value != '') {
        this.respiration = event.target.value;
      } else {
        this.isFormEmpty = true;
        this.respiration = event.target.value;
        this.respStatus = '';
      }
      if(this.respiration != '' && this.respiration != undefined && this.respiration >= 12 && this.respiration <= 20) {
        this.respStatus = 'Normal';
      } else if(this.respiration != '' && this.respiration != undefined && this.respiration > 20) {
        this.respStatus = 'Fast';
      } else if(this.respiration != ''){
        this.respStatus = 'Slow';
      }
      else {
        this.respStatus = '';
      }
    } else {
      if(event.target.value != '') {
        this.temperature = event.target.value;
      } else {
        this.isFormEmpty = true;
        this.temperature = event.target.value;
        this.tempStatus = ''
      }
      if(this.temperature != '' && this.temperature != undefined && this.temperature >= 97 && this.temperature <= 99) {
        this.tempStatus = 'Normal';
      } else if(this.temperature != '' && this.temperature != undefined && this.temperature > 99){
        this.tempStatus = 'Fever';
      }  else if(this.temperature != '' && this.temperature != undefined && this.temperature <= 96) {
        this.tempStatus = 'low'
      }
    }
  }

  getBPStatus(uplimit: any, belowlimit: any) { 
    if(uplimit > 180 && belowlimit > 120) {
      this.bpStatus = 'High';
      this.bpCategoryClass = 'High'
    } else if(uplimit < 90 && belowlimit < 60){
      this.bpStatus = 'Low';
      this.bpCategoryClass = 'low'
    } else if((uplimit >=90 && uplimit <=129) && (belowlimit >= 60 && belowlimit <=90)){ 
        this.bpStatus = 'Normal'
        this.bpCategoryClass = 'Normal'
    } 
  }

  getBMIStatus() { //to test when actual values comes
       // Determine BMI category
       if (this.bodyMassIndex < 18.5) {
        this.bmiStatus = 'Underweight';
        this.categoryClass = 'low';
      } else if (this.bodyMassIndex >= 18.5 && this.bodyMassIndex <= 24.9) {
        this.bmiStatus = 'Normal';
        this.categoryClass = 'Normal';
      } else if (this.bodyMassIndex >= 25 && this.bodyMassIndex <= 29.9) {
        this.bmiStatus = 'Overweight';
        this.categoryClass = 'High';
      } else {
        this.bmiStatus = 'Obesity';
        this.categoryClass = 'High'
      }
  }

  onPanelOpened(index: number) {
    this.activePanel = index;
    if (this.chart) {
      this.chart.update();
    }
  }

  // add the changes for the body temperature and pulse close and open
 
  public displayChart(value:any) {  
     if(value === "pulse" ){
       this.barChartData.labels = this.xAxisValues;
       this.barChartData.datasets[0].data = this.pyAxisValues;
       this.showPulseChart = true;
     }
    else if(value === 'temperature') {
     this.lineChartData.labels = this.xAxisValues;
     this.lineChartData.datasets[0].data = this.tyAxisValues;
     this.showBodyTemperature = true;
    }
    else if(value === 'heightIn') {
      this.heightBarChartData.labels = this.xAxisValues;
      this.heightBarChartData.datasets[0].data = this.hyAxisValues;
      this.showHeightChart = true;
     }
     else if(value === 'weightLbs') {
      this.weightBarChartData.labels = this.xAxisValues;
      this.weightBarChartData.datasets[0].data = this.wyAxisValues;
      this.showWeightChart = true;
     } else if(value === 'bmi') {
      this.BMIBarChartData.labels = this.xAxisValues;
      this.BMIBarChartData.datasets[0].data = this.wyAxisValues;
      this.showBMIChart = true;
     }else if(value === 'pain') {
      this.painBarChartData.labels = this.xAxisValues;
      this.painBarChartData.datasets[0].data = this.payAxisValues;
      this.showPChart = true;
     }else if(value === 'pulseOximetry') {
      this.pulseOBarChartData.labels = this.xAxisValues;
      this.pulseOBarChartData.datasets[0].data = this.poyAxisValues;
      this.showPoChart = true;
     }else if(value === 'respiration') {
      this.respBarChartData.labels = this.xAxisValues;
      this.respBarChartData.datasets[0].data = this.ryAxisValues;
      this.showRChart = true;
     }  else if(value === 'bp') {
      this.bpChartData.labels = this.xAxisValues;
      this.bpChartData.datasets[0].data = this.systolicData;
      this.bpChartData.datasets[1].data = this.dystolicData;
      this.showBPChart = true;
     } else if(value === 'circumference') {
      this.girthBarChartData.labels = this.xAxisValues;
      this.girthBarChartData.datasets[0].data = this.girthyAxisValues;
      this.showGirthChart = true;
     }
   }
  
   hideChart(value:any) {
     if(value === "pulse" ){
       this.showPulseChart = false;
     } else if(value === 'temperature') {
       this.showBodyTemperature = false;
     } else if(value === 'heightIn') {
      this.showHeightChart = false;
    } else if(value === 'weightLbs') {
      this.showWeightChart = false;
    } else if(value === 'bmi') {
      this.showBMIChart = false;
    } else if(value === 'pain') {
      this.showPChart = false;
    } else if(value === 'pulseOximetry') {
      this.showPoChart = false;
    }else if(value === 'respiration') {
      this.showRChart = false;
    } else if(value === 'bp') {
      this.showBPChart = false;
    } else if(value === 'circumference') {
      this.showGirthChart = false;
    }
   }

   stopPropagation(event: Event) {
    event.stopPropagation();
  }

  togglePanel(event: Event, value: any): void {
    // Prevent the default header click behavior
    event.stopPropagation();
    if(value === 'bp') {
      this.isBPPanelOpen = !this.isBPPanelOpen;

      if(this.isBPPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if(value === 'circumference') {
      this.isCPanelOpen = !this.isCPanelOpen;

      if(this.isCPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if(value === 'heightIn') {
      this.isHPanelOpen = !this.isHPanelOpen;

      if(this.isHPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if(value === 'weightLbs') {
      this.isWPanelOpen = !this.isWPanelOpen;

      if(this.isWPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if (value === 'bmi') {
      this.isBMIPanelOpen = !this.isBMIPanelOpen;

      if(this.isBMIPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if (value === 'pain') {
      this.isPPanelOpen = !this.isPPanelOpen;

      if(this.isPPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if (value === 'pulse') {
      this.isPuPanelOpen = !this.isPuPanelOpen;

      if(this.isPuPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if (value === 'pulseOximetry') {
      this.isPuOPanelOpen = !this.isPuOPanelOpen;

      if(this.isPuOPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else if (value === 'respiration') {
      this.isRPPanelOpen = !this.isRPPanelOpen;

      if(this.isRPPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    } else {
      this.isTPanelOpen = !this.isTPanelOpen;

      if(this.isTPanelOpen === true) {
        this.displayChart(value)
      } else {
        this.hideChart(value)
      }
    }
   
  }


  restrictToNumbers(event: any, vitalType: any): void {
    if(vitalType === 'circumference' || vitalType === 'temperature' || vitalType === 'pulseOximetry') {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
    } else {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }

  getFormattedContact() {
    const contactNumber = this.selectedPatient.contact.toString();
    const cleaned = contactNumber.replace(/\D/g, '');

    // Check if the cleaned number has exactly 10 digits
      // Format the number as XXX-XX-XXXX
      this.selectedPatient.contact = cleaned.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    }

  clearData() {
    const bpSys = document.getElementById('systolic') as HTMLInputElement;
    bpSys.value = '';
    const bpdys = document.getElementById('dystolic') as HTMLInputElement;
    bpdys.value = '';
    this.bpStatus = '';
    this.categoryClass = ''


    const girth = document.getElementById('girth') as HTMLInputElement;
    girth.value = '';
    this.circumferenceStatus = ''

    const height = document.getElementById('height') as HTMLInputElement;
    height.value = '';

    const weight = document.getElementById('weight') as HTMLInputElement;
    weight.value = '';

    this.bodyMassIndex = '';
    this.bmiStatus = '';

    const pain = document.getElementById('slider') as HTMLInputElement;
    pain.value = '0';

    const pulse = document.getElementById('pulse') as HTMLInputElement;
    pulse.value = '';
    this.pulseStatus = ''

    const pulseO = document.getElementById('pulseO') as HTMLInputElement;
    pulseO.value = '';
    this.pulseOStatus = ''

    const respiration = document.getElementById('respiration') as HTMLInputElement;
    respiration.value = '';
    this.respStatus = '';

    const temperature = document.getElementById('temperature') as HTMLInputElement;
    temperature.value = '';
    this.tempStatus = ''
    this.isFormEmpty = true;
  }

  getTodayDate() {
          var date = new Date();
          var mm = (date.getMonth() + 1).toString().padStart(2, '0');
          var yy = date.getFullYear().toString().slice(-2).padStart(2, '0');
          var newDate = mm + '/'+ yy;
          return newDate;
  }

  renderGraph() {
    this.xAxisValues.forEach((val:string, index: number) => {
      const ind = this.patientCopy.vitals.findIndex((x: any) => x.recordedAt == val);
      if(ind > -1) {
        this.puvalues[index] = this.patientCopy.vitals[ind].pulse;
        this.tvalues[index] = this.patientCopy.vitals[ind].temperature;
        this.hvalues[index] = this.patientCopy.vitals[ind].heightIn;
        this.wvalues[index] = this.patientCopy.vitals[ind].weightLbs;
        this.pvalues[index] = this.patientCopy.vitals[ind].pain;
        this.povalues[index] = this.patientCopy.vitals[ind].pulseOximetry;
        this.rvalues[index] = this.patientCopy.vitals[ind].respiration;
        this.bmiValues[index] = this.patientCopy.vitals[ind].bmi;
        this.girthValues[index] = this.patientCopy.vitals[ind].circumference;
        this.SystolicBP[index] = this.patientCopy.vitals[ind].bp.split('/')[0]
        this.diastolicBP[index] = this.patientCopy.vitals[ind].bp.split('/')[1]
      } else {
        this.puvalues[index] = 'null';
        this.tvalues[index] = 'null'
        this.hvalues[index] = 'null'
        this.wvalues[index] = 'null';
        this.pvalues[index] = 'null'
        this.povalues[index] = 'null'
        this.rvalues[index] = 'null'
        this.bmiValues[index] = 'null'
        this.girthValues[index] = 'null'
        this.SystolicBP[index] = "null"
        this.diastolicBP[index] = "null"
      }
  });
  this.pyAxisValues = this.puvalues;
  this.tyAxisValues = this.tvalues;
  this.hyAxisValues = this.hvalues;
  this.wyAxisValues = this.wvalues;
  this.payAxisValues = this.pvalues;
  this.poyAxisValues = this.povalues;
  this.ryAxisValues = this.rvalues;
  this.bmiyAxisValues = this.bmiValues;
  this.girthyAxisValues = this.girthValues;
  this.systolicData = this.SystolicBP;
  this.dystolicData = this.diastolicBP;
  }

  showRightSideContent() {
    const vitals = this.selectedPatient.vitals;
    this.graphVitals = structuredClone(vitals);
      this.graphVitals = this.graphVitals.sort((a:any, b:any) => {
        return Date.parse(b.recordedAt) - Date.parse(a.recordedAt);
      });
      const updateData = structuredClone(this.graphVitals);
      updateData.forEach((element:any, index: any) => {
        var date = new Date(element.recordedAt);
        var mm = (date.getMonth() + 1).toString().padStart(2, '0');
        var yy = date.getFullYear().toString().slice(-2).padStart(2, '0');
        var newDate = mm + '/'+ yy;
        element.recordedAt = newDate;
        if(element.recordedAt === "01/24") {
          this.graphVitals.splice(index, 1);
          updateData.splice(index, 1);
        } else {
          if(element.bp !=null) {
            const bpParts = element.bp.split('/');
            this.graphVitals[index]['systolic'] = bpParts[0];
            this.graphVitals[index]['dystolic'] = bpParts[1];
          }
        }
        
      });
  }

  closeAllPanels() {
    if(this.isBPPanelOpen === true) {
      this.isBPPanelOpen = !this.isBPPanelOpen
    } else if (this.isCPanelOpen === true) {
      this.isCPanelOpen = !this.isCPanelOpen
    } else if(this.isHPanelOpen === true) {
      this.isHPanelOpen = !this.isHPanelOpen
    } else if(this.isWPanelOpen === true) {
      this.isWPanelOpen = !this.isWPanelOpen;
    } else if(this.isBMIPanelOpen === true) {
       this.isBMIPanelOpen = !this.isBMIPanelOpen
    } else if(this.isPPanelOpen === true) {
      this.isPPanelOpen = !this.isPPanelOpen;
    } else if(this.isPuPanelOpen === true) {
      this.isPuPanelOpen = !this.isPuPanelOpen
    } else if(this.isPuOPanelOpen === true) {
      this.isPuOPanelOpen = !this.isPuOPanelOpen
    } else if (this.isRPPanelOpen === true) {
      this.isRPPanelOpen = !this.isRPPanelOpen;
    } else if(this.isTPanelOpen === true) {
      this.isTPanelOpen = !this.isTPanelOpen;
    }
  }

  newFormValidations() {
    this.vitalsForm = this.fb.group({
      temperature: [null,[Validators.required, Validators.min(95), Validators.max(104)]],
      respiration:  [null, [Validators.required, Validators.min(12), Validators.max(20)]],
      pulseOximetry: [null,[Validators.required, Validators.min(90), Validators.max(100)]],
      pulse: [null,[Validators.required, Validators.min(40), Validators.max(100)]],
      circumference: [null,[Validators.required, Validators.min(28), Validators.max(40)]],
      heightIn: [null,[Validators.required, Validators.min(21), Validators.max(108)]],
      weightLbs: [null,[Validators.required, Validators.min(80), Validators.max(600)]],
      BPDystolicvalue: [null,[Validators.required, Validators.min(60), Validators.max(120)]],
      BPSystolicvalue: [null,[Validators.required, Validators.min(90), Validators.max(200)]],
    })
  }

  validationChange(value: any) {
    if(this.vitalsForm.get('temperature')?.hasError('max') || this.vitalsForm.get('temperature')?.hasError('min') ||
 this.vitalsForm.get('respiration')?.hasError('max') || this.vitalsForm.get('respiration')?.hasError('min') ||
 this.vitalsForm.get('pulseOximetry')?.hasError('max') || this.vitalsForm.get('pulseOximetry')?.hasError('min') ||
 this.vitalsForm.get('pulse')?.hasError('max') || this.vitalsForm.get('pulse')?.hasError('min') ||
 this.vitalsForm.get('circumference')?.hasError('max') ||  this.vitalsForm.get('circumference')?.hasError('min')
|| this.vitalsForm.get('heightIn')?.hasError('max')|| this.vitalsForm.get('heightIn')?.hasError('min') ||
this.vitalsForm.get('weightLbs')?.hasError('max') || this.vitalsForm.get('weightLbs')?.hasError('min') ||
this.vitalsForm.get('BPSystolicvalue')?.hasError('max') || this.vitalsForm.get('BPSystolicvalue')?.hasError('min') ||
this.vitalsForm.get('BPDystolicvalue')?.hasError('max') || this.vitalsForm.get('BPDystolicvalue')?.hasError('min'))

 {
   this.isFormEmpty = true;
 } else {
   this.isFormEmpty = false;
 }
}


}
