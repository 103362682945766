<div class="loader-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>

<div class="top-container">
  <mat-card class="patient-info">
    <mat-card-header>
          <div mat-card-avatar class="profile-picture"></div>
          <mat-card-title>{{ selectedPatient.patientName }}, {{ age }} yo</mat-card-title>
          <mat-card-subtitle>
            <div class="fingerprint-section">
              <mat-icon>fingerprint</mat-icon>
              <p class="fingerprint">{{ selectedPatient.contact }}</p>
              <mat-icon style="margin-left: 10px">cake</mat-icon>
              <p class="fingerprint">
                {{ selectedPatient.dob | date : "MM/dd/yyyy" }}
              </p>
            </div>
          </mat-card-subtitle>
    </mat-card-header>
    <mat-card-header class="hospital-header">
      <mat-card-title>Today {{today | date: 'MM/dd/yyyy'}}</mat-card-title>
      <mat-card-subtitle>
        <div class="fingerprint-section">
          <mat-icon class="hospital-icon">local_hospital</mat-icon>
          <p class="fingerprint">Metropolis Health Center</p>
        </div>
      </mat-card-subtitle>
</mat-card-header>
  </mat-card>
</div>

<div class="row content-row">
  <div class="col-md-8">
    <form [formGroup]="vitalsForm">
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header" [expanded]="isBPPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="assets/images/blood pressure.png" height="35" width="42"/><span class="vital-name">Blood Pressure</span>
          </mat-panel-title>
          <mat-panel-description class="description-header">
            <div class="blood-pressure">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'bp', 'upperlimit')" (input)="restrictToNumbers($event, 'bp')" id="systolic" formControlName="BPSystolicvalue"/> <span class="blood-pressure-input"> / </span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('BPSystolicvalue')?.hasError('min')">systolic value must be min 90</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('BPSystolicvalue')?.hasError('max')">systolic value must be max 200</div>
              <input (change)="getValue($event, 'bp', 'belowlimit')" type="text" class="field-values" value="" (input)="restrictToNumbers($event, 'bp')" id="dystolic"  formControlName="BPDystolicvalue"/> <span class="unit">mm/Hg</span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('BPDystolicvalue')?.hasError('min')">dystolic value must be min 60</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('BPDystolicvalue')?.hasError('max')">dystolic value must be max 120</div>
            </div>
          </mat-panel-description>
          <mat-panel-description class="description-status"> <p [ngClass]="bpCategoryClass">{{ bpStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row" [ngClass]="{'align-row' : (vitalsForm.get('BPDystolicvalue')?.hasError('min') || vitalsForm.get('BPDystolicvalue')?.hasError('max')) || (vitalsForm.get('BPSystolicvalue')?.hasError('max') || vitalsForm.get('BPSystolicvalue')?.hasError('min') )}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.bp}} mm/Hg</div>
            </div>
            <div class="row">
              <div class="col icon"></div>
              <div class="col last-recorded  value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'bp')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showBPChart" style="display: block;">
          <canvas baseChart
                  [data]="bpChartData"
                  [options]="bpLineChartOptions"
                  type="line">
          </canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Circumference/Girth Accordion -->

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header" [expanded]="isCPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/circumference.png" height="30" width="30"/><span class="vital-name">Circumference/Girth </span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="circumference">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'circumference')" (input)="restrictToNumbers($event, 'circumference')" id="girth" formControlName="circumference"/> <span class="unit">inches</span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('circumference')?.hasError('min')">Circumference must be min 28 inch.</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('circumference')?.hasError('max')">Circumference must be max 40 inch.</div>
            </div>
          </mat-panel-description>
          <mat-panel-description class="description-status"><p [ngClass]="circumferenceStatus">{{ circumferenceStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row" [ngClass]="{'align-row' : vitalsForm.get('circumference')?.hasError('min') || vitalsForm.get('circumference')?.hasError('max')}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.circumference}} in</div>
            </div>
            <div class="row">
              <div class="col icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'circumference')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showGirthChart" style="display: block;">
          <canvas baseChart
                  [data]="girthBarChartData"
                  [options]="girthBarChartOptions"
                  chartType="bar">
          </canvas>
        </div> 
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Height Accordion -->
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header" [expanded]="isHPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/height.png" height="30" width="30"/><span class="vital-name">Height</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="other-vitals height-div">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'heightIn')" (input)="restrictToNumbers($event, 'heightIn')" id="height" formControlName="heightIn"/> <span class="unit">inches</span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('heightIn')?.hasError('min')">Height must be min 21 inch.</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('heightIn')?.hasError('max')">Height must be max 108 inch.</div>
            </div>
          </mat-panel-description>
          <mat-panel-description><p [ngClass]="heightStatus">{{ heightStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row" [ngClass]="{'align-row' : vitalsForm.get('heightIn')?.hasError('min') || vitalsForm.get('heightIn')?.hasError('max')}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.heightIn}} in</div>
            </div>
            <div class="row">
              <div class="col icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'heightIn')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>

         <div *ngIf="showHeightChart" style="display: block;">
          <canvas baseChart
                  [data]="heightBarChartData"
                  [options]="heightBarChartOptions"
                  chartType="bar">
          </canvas>
        </div> 
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Weight Accordion -->
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel (click)="stopPropagation($event)" class="expansion-header" [expanded]="isWPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/weight.png" height="30" width="30"/><span class="vital-name">Weight</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="other-vitals weight-div">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'weightLbs')" (input)="restrictToNumbers($event, 'weightLbs')" id="weight" formControlName="weightLbs"/> <span class="unit">lbs</span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('weightLbs')?.hasError('min')">Weight must be min 80lbs.</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('weightLbs')?.hasError('max')">Weight must be max 600lbs.</div>
            </div>
          </mat-panel-description>
          <mat-panel-description><p [ngClass]="weightStatus">{{ weightStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row"  [ngClass]="{'align-row' : vitalsForm.get('weightLbs')?.hasError('min') || vitalsForm.get('weightLbs')?.hasError('max')}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.weightLbs}} lbs</div>
            </div>
            <div class="row">
              <div class="col icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'weightLbs')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showWeightChart" style="display: block;">
          <canvas baseChart
                  [data]="weightBarChartData"
                  [options]="weightBarChartOptions"
                  chartType="bar">
          </canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Body Mass Index Accordion -->
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header"  [expanded]="isBMIPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/bmi.png" height="30" width="30"/><span class="vital-name">Body Mass Index</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="body-mass-index"> <p class="body-mass-index">{{bodyMassIndex}}</p> </div>
          </mat-panel-description>
          <mat-panel-description> <p [ngClass]="categoryClass">{{ bmiStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row" >
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.bmi}}</div>
            </div>
            <div class="row">
              <div class="col icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'bmi')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showBMIChart" style="display: block;">
          <canvas baseChart
                  [data]="BMIBarChartData"
                  [options]="BMIBarChartOptions"
                  chartType="bar">
          </canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Pain Accordion_ -->
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header" [expanded]="isPPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/pain.png" height="30" width="30"/><span class="vital-name">Pain</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="pain-slider">
              <input class="range" type="range" class="custom-range" min="0" max="10" step="1" id="slider" value="0" (change)="getValue($event, 'pain')"  (click)="stopPropagation($event)"/>  
              <div class="range-labels mt-2">
                <span *ngFor="let pain of painRange"> {{pain}} </span>
              </div>
            </div>
          </mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row pain-row">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.pain}}</div>
            </div>
            <div class="row pain-row">
              <div class="col  icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'pain')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showPChart" style="display: block;">
          <canvas baseChart
                  [data]="painBarChartData"
                  [options]="painBarChartOptions"
                  chartType="bar">
          </canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Pulse Accordion -->

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel  class="expansion-header" [expanded]="isPuPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/pulse.png" height="30" width="30"/><span class="vital-name">Pulse</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="other-vitals">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'pulse')" (input)="restrictToNumbers($event, 'pulse')" id="pulse" formControlName="pulse"/> <span class="unit">beats per minute</span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('pulse')?.hasError('min')">Pulse must be min 40 bpm.</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('pulse')?.hasError('max')">Pulse must be max 100 bpm.</div>  
            </div>
          </mat-panel-description>
          <mat-panel-description><p [ngClass]="pulseStatus">{{ pulseStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row"  [ngClass]="{'align-row' : vitalsForm.get('pulse')?.hasError('min') || vitalsForm.get('pulse')?.hasError('max')}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.pulse}} bpm</div>
            </div>
            <div class="row">
              <div class="col icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'pulse')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showPulseChart" style="display: block;">
          <canvas baseChart
                  [data]="barChartData"
                  [options]="barChartOptions"
                  chartType="bar">
          </canvas>
        </div>
        
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Pulse Oximetry Accordion -->

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header" [expanded]="isPuOPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/hemoglobin.png" height="30" width="30"/><span class="vital-name">Pulse Oximetry</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="other-vitals">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'pulseOximetry')" (input)="restrictToNumbers($event, 'pulseOximetry')" id="pulseO"  formControlName="pulseOximetry"/> <span class="unit">SpO2</span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('pulseOximetry')?.hasError('min')">Pulse oximetry must be min 90.</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('pulseOximetry')?.hasError('max')">Pulse oximetry must be max 100.</div>
            </div>
          </mat-panel-description>
          <mat-panel-description><p [ngClass]="pulseOStatus">{{ pulseOStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row" [ngClass]="{'align-row' : vitalsForm.get('pulseOximetry')?.hasError('min') || vitalsForm.get('pulseOximetry')?.hasError('max')}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.pulseOximetry}} SpO2</div>
            </div>
            <div class="row">
              <div class="col  icon"></div>
              <div class="col last-recorded  value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'pulseOximetry')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showPoChart" style="display: block;">
          <canvas baseChart
                  [data]="pulseOBarChartData"
                  [options]="pulseOBarChartOptions"
                  chartType="bar">
          </canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Respiration Accordion -->

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header" [expanded]="isRPPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/respiration.png" height="30" width="30"/><span class="vital-name">Respiration</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="other-vitals">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'respiration')" (input)="restrictToNumbers($event, 'respiration')" id="respiration" formControlName="respiration"/> <span class="unit">beats per minute</span>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('respiration')?.hasError('min')">Respiration must be min 12 bpm.</div>
              <div class="range-errors Abnormal" *ngIf="vitalsForm.get('respiration')?.hasError('max')">Respiration must be max 20 bpm.</div>
            </div>
          </mat-panel-description>
          <mat-panel-description><p [ngClass]="respStatus">{{ respStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row" [ngClass]="{'align-row' : vitalsForm.get('respiration')?.hasError('min') || vitalsForm.get('respiration')?.hasError('max')}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.respiration}} bpm</div>
            </div>
            <div class="row">
              <div class="col  icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'respiration')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showRChart" style="display: block;">
          <canvas baseChart
                  [data]="respBarChartData"
                  [options]="respBarChartOptions"
                  chartType="bar">
          </canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Body Temperature Accordion -->

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel class="expansion-header" [expanded]="isTPanelOpen" [disabled]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: 10px;">
            <img src="/assets/images/temperature.png" height="30" width="30"/><span class="vital-name">Body Temperature</span>
          </mat-panel-title>
          <mat-panel-description class="others-description-header">
            <div class="other-vitals">
              <input type="text" class="field-values" value="" (change)="getValue($event, 'temperature')" (input)="restrictToNumbers($event, 'temperature')" id="temperature" formControlName="temperature"/> <span class="unit"> <span>&#176;F</span></span>
              <div *ngIf="vitalsForm.get('temperature')?.hasError('min')" class="range-errors Abnormal">Temperature must be min 95°F.</div>
              <div *ngIf="vitalsForm.get('temperature')?.hasError('max')" class="range-errors Abnormal">Temperature must be max 104°F.</div>  
            </div>
          </mat-panel-description>
          <mat-panel-description><p [ngClass]="tempStatus">{{ tempStatus }}</p></mat-panel-description>
          <mat-panel-description class="last-recorded-value">
            <div class="row" [ngClass]="{'align-row' : vitalsForm.get('temperature')?.hasError('min') || vitalsForm.get('temperature')?.hasError('max')}">
              <div class="col icon"><div class="last-recorded-icon"></div></div>
              <div class="col value">{{latestRecording?.temperature}}  <span>&#176;F</span></div>
            </div>
            <div class="row">
              <div class="col icon"></div>
              <div class="col last-recorded value">{{latestRecording?.recordedAt | date: 'MM/dd/yy'}}</div>
            </div> 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-icon-button (click)="togglePanel($event, 'temperature')" class="custom-icon" style="visibility: visible !important;">
          <mat-icon style="visibility: visible !important;">expand_more</mat-icon>
        </button>
        <div *ngIf="showBodyTemperature">
          <canvas baseChart
                  [data]="lineChartData"
                  [options]="lineChartOptions"
                  type="line">
          </canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <button type="submit" (click)="updateData()" class="submitbtn" [disabled]="isFormEmpty || vitalsForm.invalid"  [ngClass]="{'disabled-btn': isFormEmpty  || vitalsForm.invalid}" >
      Save
    </button>
  </form>
  </div>
    <div class = "col-md-4">   
       <div class="timeline">
      <div *ngFor="let vital of graphVitals" class="timeline-entry">
        <div class="timeline-entry">
          <div class="timeline-line"></div>
          <div class="timeline-icon">
            <!-- Outer Circle -->
            <div class="inner-circle">
              <span><img src="assets/images/heartbeat.png" class="img-fluid-right-icon"></span>
            </div>
          </div>
          <div class="timeline-card">
            <h4>{{vital?.recordedAt | date: 'MM/dd/yy hh:mm a' }}</h4>
            <div class="timeline-details">
               <div class="row vital-table-row first-row">
                <div class="col-md-8 vital-item border-right border">
                  <img
                  src="assets/images/blood pressure.png"
                  alt="Blood Pressure"
                  class="img-fluid"
                />
                  <span class="text-center value-text"  [ngClass]="{'Abnormal' : (vital?.dystolic !=null && vital?.systolic !=null) && (vital?.systolic > '129' && vital?.dystolic >'80'), 'low': (vital?.systolic < '90' && vital?.dystolic <'60')}"> {{vital?.bp !=null ? vital?.bp : '--'}}</span><span [ngClass]="{'Abnormal' : (vital?.dystolic !=null && vital?.systolic !=null) && (vital?.systolic >  '129' && vital?.dystolic >'80'), 'low': (vital?.systolic < '90' && vital?.dystolic <'60')}">mmHg</span>
                 
                </div>
               
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="assets/images/circumference.png"
                    alt="Circumference/Girth"
                    class="img-fluid" 
                  />
                  <span class="text-center value-text" [ngClass]="{'Abnormal' : !(vital?.circumference >= 28 && vital?.circumference <=40), 'low': vital?.circumference < 10}">{{vital?.circumference || '--'}}</span><span [ngClass]="{'Abnormal' : !(vital?.circumference >= 28 && vital?.circumference <=40),'low': vital?.circumference < 10}">in</span>
                </div>
               
        
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="assets/images/height.png"
                    alt="Height"
                    class="img-fluid"
                  />
                  <span class="text-center value-text">{{vital?.heightIn}}</span><span *ngIf="vital?.heightIn != ''">in</span>
                </div>
        
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="assets/images/weight.png"
                    alt="Weight"
                    class="img-fluid"
                  />
                  <span class="text-center value-text">{{vital?.weightLbs}}</span><span *ngIf="vital?.weightLbs != ''">lbs</span>
                </div>
        
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="assets/images/bmi.png"
                    alt="Body Mass Index"
                    class="img-fluid"
                  />
                  <span class="text-center value-text" [ngClass]="{'Abnormal' : vital?.bmi !=null && vital?.bmi >= 25, 'low': vital?.bmi < 18.5}">{{vital?.bmi != null ? vital?.bmi : '--'}}</span><span [ngClass]="{'Abnormal' : vital?.bmi !=null && vital?.bmi >= 25, 'low': vital?.bmi < 18.5}">bmi</span>
                </div>
               
              </div>
              <div class="row vital-table-row second-row">
                <div class="col-md-8 vital-item border-right border">
                  <img src="assets/images/pain.png" alt="Pain" class="img-fluid" />
                  <span class="text-center value-text" [ngClass]="{'Abnormal' : vital?.pain != null && (vital?.pain >= 8 && vital?.pain <=10)}">{{vital?.pain !=null ? vital?.pain: '--'}}</span><span [ngClass]="{'Abnormal' : vital?.pain != null && (vital?.pain >= 8 && vital?.pain <=10)}">pain</span>
                </div>
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="assets/images/pulse.png"
                    alt="Pulse"
                    class="img-fluid"
                  />
                  <span class="text-center value-text" [ngClass]="{'Abnormal' : vital?.pulse != null && vital?.pulse > 120, 'low' : vital?.pulse < 50 }">{{vital?.pulse != null ? vital?.pulse : '--'}}</span><span  [ngClass]="{'Abnormal' : vital?.pulse != null && vital?.pulse > 120, 'low' : vital?.pulse < 50}" *ngIf="vital?.pulse !=null">bpm</span>
                </div>
        
                  
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="assets/images/hemoglobin.png"
                    alt="Pulse Oximetry"
                    class="img-fluid"
                  />
                  <span class="text-center value-text" [ngClass]="{'low' : vital?.pulseOximetry !=null && vital?.pulseOximetry < 90}">{{vital?.pulseOximetry != null ? vital?.pulseOximetry : '--'}}</span><span  [ngClass]="{'low' : vital?.pulseOximetry !=null && vital?.pulseOximetry < 90}" *ngIf="vital?.pulseOximetry !=null">SpO2</span>
                </div>
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="/assets/images/respiration.png"
                    alt="Respiration"
                    class="img-fluid-respiration"
                  />
                  <span class="text-center value-text" [ngClass]="{'Abnormal' : vital?.respiration != null && (vital?.respiration >20 ), 'low' : vital?.respiration < 12}">{{vital?.respiration !=null ? vital?.respiration : '--'}}</span><span [ngClass]="{'Abnormal' : vital?.respiration != null && (vital?.respiration >20 ),'low' : vital?.respiration < 12}" *ngIf="vital?.respiration !=null">bpm</span>
                </div>
                <div class="col-md-8 vital-item border-right border">
                  <img
                    src="/assets/images/temperature.png"
                    alt="Body Temperature"
                    class="img-fluid"
                  />
                  <span class="text-center value-text" [ngClass]="{'Abnormal' : vital?.temperature != null && vital?.temperature > 99, 'low': vital?.temperature < 96}">{{vital?.temperature !=null ? vital?.temperature : '--'}}</span><span [ngClass]="{'Abnormal' : vital?.temperature != null && vital?.temperature > 99, 'low': vital?.temperature <95}" *ngIf="vital?.temperature !=null">&#176;F</span>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
   

  </div>


