export interface EnvironmentConfig {
    production: Boolean,
    DOMAIN_NAME: String,
    jsonBaseUrl: string;
  }
  
  export const environment: EnvironmentConfig = {
    production: false,
    DOMAIN_NAME: '',
    jsonBaseUrl: '',
  };
  
   