<div class="loader-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>
<div class ="form-container">
  <form [formGroup]="patientForm" (ngSubmit)="onSubmit()" >
    
    <div>
      <label for="inputText">Patient Name:</label>
      <input type="text" id="patientName" formControlName="patientName" (change)="checkValidity($event)"/>
    </div>

    <div *ngIf="(patientForm.get('patientName')?.invalid && patientForm.get('patientName')?.touched) || errorMessage !=''">
      <span style="color: red;">{{errorMessage}}</span>
    </div>
    <button type="submit" [disabled]="isValid === false" mat-raised-button color="primary" [ngClass]="{'disabled-btn': isValid === false}">Submit</button>
  </form>
</div>


